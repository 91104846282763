export default {
  namespaced: true,

  state: {
    alert: { type: "error", text: "" },
    loader: false,
  },

  getters: {
    getAlert(state) {
      return state.alert;
    },
    getLoader(state) {
      return state.loader;
    },
  },

  mutations: {
    SET_ALERT(state, value) {
      state.alert = { type: value.type, text: value.text };
    },
    SET_LOADER(state, value) {
      state.loader = value;
    },
  },

  actions: {
    async setAlert({ commit }, alert) {
      commit("SET_ALERT", alert);
    },
    async setLoader({ commit }, state) {
      commit("SET_LOADER", state);
    },
  },
};
