export default {
  namespaced: true,

  state: {
    orders: {
      sort: {name: "id", asc: false},
      perPage: 50,
      search: '',
      view: true,
      withTrashed: false,
      settings: {
        actual: false,
        created: true,
        working: true,
        postponed: true,
        finished: true,
        canceled: true,
      },
      columnOrder: null,
    },
    seo: {
      sort: {name: "id", asc: false},
      perPage: 50,
      search: '',
      view: true,
      special: 0,
      columnOrder: null,
    },
    positions: {
      sort: {name: "id", asc: false},
      perPage: 50,
      search: '',
      view: true,
      columnOrder: null,
    },
    catsGallery: {
      sort: {name: "id", asc: false},
      perPage: 50,
      search: '',
      view: true,
      columnOrder: null,
    },
    catsMedia: {
      sort: {name: "id", asc: false},
      perPage: 50,
      search: '',
      view: true,
      columnOrder: null,
    },
    catsServices: {
      sort: {name: "id", asc: false},
      perPage: 50,
      search: '',
      view: true,
      columnOrder: null,
    },
    media: {
      sort: {name: "id", asc: false},
      perPage: 50,
      search: '',
    },
    compartments: {
      sort: {name: "id", asc: false},
      perPage: 50,
      search: '',
      view: true,
      columnOrder: null,
    },
    departments: {
      sort: {name: "ord", asc: true},
      perPage: 50,
      search: '',
      view: true,
      columnOrder: null,
      titlePhotos: null,
      titleVideos: null
    },
    doctors: {
      sort: {name: "ord", asc: true},
      perPage: 50,
      search: '',
      view: true,
      columnOrder: null,
    },
    faq: {
      sort: {name: "id", asc: false},
      perPage: 50,
      search: '',
      view: true,
      columnOrder: null,
    },
    common: {
      sort: {name: "id", asc: false},
      perPage: 50,
      search: '',
      view: true,
      columnOrder: null,
    },
    packages: {
      sort: {name: "id", asc: false},
      perPage: 50,
      search: '',
      view: true,
      columnOrder: null,
    },
    blog: {
      sort: {name: "id", asc: false},
      perPage: 50,
      search: '',
      view: true,
      columnOrder: null,
    },
    logs: {
      sort: {name: "id", asc: false},
      perPage: 50,
      search: '',
      view: true,
      columnOrder: null,
    },
    roles: {
      sort: {name: "id", asc: false},
      perPage: 50,
      search: '',
      view: true,
      columnOrder: null,
    },
    settings: {
      sort: {name: "id", asc: false},
      perPage: 50,
      search: '',
      view: true,
      columnOrder: null,
    },
    currency: {
      sort: {name: "id", asc: false},
      perPage: 50,
      search: '',
      view: true,
      columnOrder: null,
    },
    users: {
      sort: {name: "id", asc: false},
      perPage: 50,
      search: '',
      view: true,
      columnOrder: null,
    },
    services: {
      sort: {name: "ord", asc: true},
      perPage: 50,
      search: '',
      view: true,
      columnOrder: null,
      titlePhotos: null,
      titleVideos: null
    },
    redirects: {
      sort: {name: "id", asc: false},
      perPage: 50,
      search: '',
    },
  },
  mutations: {
    SET_PAGES(state, value) {
      state[value.page][value.name] = value.data;
    },
    SET_SETTINGS(state, value) {
      state[value.page].settings[value.name] = value.data;
    },
  },
  actions: {
    async setPages({ commit }, state) {
      commit("SET_PAGES", state);
    },
  },
};
