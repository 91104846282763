<template>
  <div class="admin__wrapper">
    <div
      class="burger__wrapper"
      :class="{ slide: this.$store.state.perm_ui.sidebar }"
    >
      <burger-menu
        class="hide--tablet"
        :class="{ active: this.$store.state.perm_ui.sidebar }"
      >
      </burger-menu>
    </div>

    <sidebar-menu />

    <router-view>
      <template v-slot="{ Component }">
        <transition name="alert" mode="out-in">
          <component :is="Component" />
        </transition>
      </template>
    </router-view>
    <teleport to="body">
      <transition name="alert">
        <base-alert
          v-if="$store.state.ui.alert.text"
          :text="$store.state.ui.alert.text"
          :type="$store.state.ui.alert.type"
        />
      </transition>
    </teleport>
  </div>
</template>

<script>
import BurgerMenu from "@/components/UI/BurgerMenu.vue";
import BaseAlert from "./components/functional/BaseAlert.vue";
// eslint-disable-next-line no-unused-vars
import ViewButton from "@/components/UI/ViewButton.vue";
import { mapGetters } from "vuex";
import SidebarMenu from "./components/UI/SidebarMenu.vue";

export default {
  name: "App",
  components: {
    BaseAlert,
    SidebarMenu,
    BurgerMenu,
  },
  data() {
    return {
      currentUser: this.user,
    };
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },
  created() {
    this.currentUser = this.user;
  },
  watch: {
    user(newValue) {
      this.currentUser = newValue;
    },
  },
};
</script>

<style lang="less">
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500&display=swap");

:root {
  --green: #23a954;
  --yellow: #9e9420;
  --pink: #bc81c7;
  --red: #cb4c4c;
  --primary: #1d678c;
  --secondary: #bc81c7;
  --dark-color: #31344b;
  --placeholders: #7a7a7a;
  --semidark-color: #bbbbbb;
  --bg: #e5e9ef;
  --round-high: 100px;
  --round-mid: 12px;
  --round-light: 4px;
}

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Rubik", sans-serif;
}

@media (min-width: 1024px) {
  * {
    scrollbar-color: var(--primary) transparent;
    scrollbar-width: thin;
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--primary);
    border-radius: var(--round-high);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: var(--secondary);
    cursor: pointer;
  }
}

.hide--phone {
  @media (max-width: 767px) {
    display: none !important;
  }
}

.hide--tablet {
  @media (min-width: 768px) {
    display: none !important;
  }
}

h1,
h2,
h3 {
  margin-top: 0;
  font-weight: 500;
  text-transform: uppercase;
}

.burger__wrapper {
  position: absolute;
  top: 10px;
  left: 10px;
  transform: translateX(0px);
  transition: 0.25s;
  z-index: 9;
  @media (max-width: 767px) {
    left: unset;
    right: 8px;
  }
}

.back__wrapper {
  @media (max-width: 767px) {
    margin-right: 10px;
  }
}

// HEADER
.page-title {
  margin-bottom: 0;
  white-space: nowrap;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  text-transform: none;
  line-height: 18px;
  text-align: left;
}
.header {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
  min-height: 60px;
  max-height: 60px;
  padding: 10px 10px 10px;
  background-color: var(--bg);
  text-align: left;
  width: 100%;
  margin-bottom: 0px;

  @media (max-width: 767px) {
    flex-wrap: wrap;
    max-height: none;
    flex-shrink: 0;
    padding: 5px 8px 8px;
    margin: 8px;
    box-shadow: -3px -3px 6px #fafbff, 3px 3px 6px 0px #a6abbdab;
    border-radius: var(--round-mid);
    width: auto;
  }

  .page-title {
    min-width: 25%;
    margin-right: auto;
  }

  > a + .page-title {
    transform: translateX(-20px);
    animation: translate 1s forwards;
    transition: 1s;

    @media (max-width: 767px) {
      // transform: translateX(0px);
      animation: translate 1s forwards;
      margin-right: 15px;
      margin-left: -15px;
    }
  }

  .button--new {
    text-align: center;
  }
}
.flipless .header {
  box-shadow: none;
  margin: 0;
}
.back__button {
  padding: 0 5px;
  font-size: 20px;
  line-height: 32px;
  color: var(--dark-color);
  opacity: 0;
  display: inline-block;
  width: 20px;
  animation: opacity 2s forwards;
  transition: 1s;

  &.show {
    opacity: 1;
    transition: 1s;
  }

  display: inline-block;
  height: 32px;
  width: 32px;
  overflow: visible;
  position: relative;
  text-align: center;
  border: none;
  border-radius: var(--round-high);
  cursor: pointer;
  box-sizing: border-box;
  background-blend-mode: soft-light, normal;
  border: 2px solid var(--bg);
  transition: 0.3s;
  background: var(--bg);
  box-shadow: -2px -2px 6px #ffffff, 3px 3px 6px rgba(174, 174, 192, 0.4);
}
//--- HEADER

body {
  padding: 0;
  color: var(--dark-color);
  font-family: sans-serif;
  transition: 0.3s;
  margin: 0 !important;
  overflow: hidden;
}

.card {
  width: 100%;
  border-radius: var(--round-mid);
  padding: 40px;
  margin-bottom: 0;
  background: linear-gradient(
      317.7deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(255, 255, 255, 0.4) 105.18%
    ),
    var(--bg);
  background-blend-mode: soft-light, normal;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-shadow: -5px -5px 10px #fafbff, 5px 5px 10px #a6abbdab;

  @media (max-width: 1280px) {
    padding: 20px;
  }

  @media (max-width: 767px) {
    padding: 10px;
  }
}

.content__wrapper {
  width: 100%;
  flex: 1;
  // height: 100%;
  border-radius: var(--round-mid);
  padding: 0 10px;
  background-color: var(--bg);
  margin-bottom: 10px;
  position: relative;
  overflow-y: auto;

  @media (max-width: 767px) {
    padding: 0 8px;
    margin-bottom: 0;
    margin-top: 10px;
  }

  .content__wrapper {
    overflow: visible;
  }
}

.flipless .content__wrapper {
  @media (max-width: 767px) {
    margin-bottom: 10px;
    margin-top: 0px;
  }
}

// SIDEBAR
.sidebar__wrapper + .content-wrapper {
  @media (max-width: 1024px) {
    transform: translateX(190px);
    transition: 0.25s;
    animation-timing-function: linear;
  }

  @media (max-width: 767px) {
    transform: translateX(-260px);
    transition: 0.25s;
    animation-timing-function: linear;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    padding-left: 90px;
  }
}

.sidebar__wrapper.closed + .content-wrapper {
  @media (max-width: 1024px) {
    transform: translateX(0px);
    transition: 0.25s;
    animation-timing-function: linear;
  }

  @media (max-width: 767px) {
    // transform: translateX(-100%);
    transition: 0.25s;
    animation-timing-function: linear;
  }
}
//--- SIDEBAR

// MODAL
.modal {
  .content--form {
    padding: 0px;
    display: flex;
    height: 100%;
    max-width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
  .content-wrapper {
    height: 100%;
  }
}

//--- MODAL

.content-wrapper {
  flex: 1;
  overflow: hidden;
  padding: 0;
  background-color: var(--bg);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  transition: 0.25s;
  animation-timing-function: linear;
}

.content {
  width: 100%;
  min-height: 100%;
  padding-top: 0;
  /* margin-bottom: -10px; */
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media (max-width: 767px) {
    align-items: unset;
    padding-bottom: 80px;
    &:not(.flipless) {
      flex-direction: column-reverse;
    }
  }
}

// FORM
.content--form {
  padding-bottom: 0px;
  @media (max-width: 1279px) {
    padding-bottom: 80px;
  }
  h3 {
    margin-bottom: 30px;
    text-align: center;
    @media (max-width: 1280px) {
      margin-bottom: 16px;
      width: 100%;
    }
  }
  form {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
label {
  display: block;
  font-size: 14px;
  width: 100%;
  font-weight: 500;
  margin-bottom: 5px;
  // @media (max-width: 1280px) {
  //   font-size: 16px;
  // }
}
.form__inputs {
  width: 100%;
  display: flex;
  flex-direction: column;
  > div {
    width: 100%;
    margin-bottom: 20px;
  }
}

.form__submit {
  text-align: right;
  margin-left: auto;
  margin-top: 20px;
  margin-bottom: 0;
}

.take-space {
  flex: 1;
  display: flex;
  flex-direction: column;
}
//--- FORM

input,
textarea,
select {
  color: var(--dark-color);
  resize: none;
  width: 100%;
  appearance: none;
  padding: 0 10px;
  font-size: 15px;
  line-height: 38px;
  text-align: center;
  border-radius: var(--round-high);
  border-radius: var(--round-mid);
  margin-bottom: 0;
  // background: linear-gradient(
  //     317.7deg,
  //     rgba(0, 0, 0, 0.4) 0%,
  //     rgba(255, 255, 255, 0.4) 105.18%
  //   ),
  //   var(--bg);
  background: var(--bg);
  background-blend-mode: soft-light, normal;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-shadow: inset -2px -2px 4px #fafbff, inset 2px 2px 4px 0px #a6abbd;
  &::placeholder {
    color: var(--placeholders);
    font-family: sans-serif;
  }
}
textarea {
  line-height: 1.15;
  text-align: left;
  padding: 15px 10px;
}

input:-webkit-autofill,
input:-internal-autofill-previewed,
input:-internal-autofill-selected {
  transition: all 0s 50000s;
}

a {
  cursor: pointer;
  text-decoration: none;
}

// Standart button
button,
.button {
  border-radius: var(--round-mid);
  /* color: var(--primary); */
  font-size: 14px;
  /* text-transform: uppercase; */
  padding: 10px 20px;
  position: relative;
  cursor: pointer;
  @media (max-width: 767px) {
    padding: 5px 10px;
  }
  background: linear-gradient(
      317.7deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(255, 255, 255, 0.4) 105.18%
    ),
    var(--bg);
  background-blend-mode: soft-light, normal;
  border: 2px solid var(--bg);
  box-shadow: -2px -2px 4px #ffffff, 2px 2px 4px 0px rgb(174 174 192 / 40%);

  &:hover {
    border: 2px solid rgba(255, 255, 255, 0.25);
    background-color: var(--bg);
    box-shadow: -2px -2px 4px #ffffff, 2px 2px 4px 0px rgb(174 174 192 / 40%);
    transition: 0.3s;
  }

  &:focus,
  &:active {
    border: 2px solid var(--bg);
    background-color: var(--bg);
    box-shadow: inset -1px -1px 1px rgba(255, 255, 255, 0.7),
      inset 1.5px 1.5px 1px rgba(174, 174, 192, 0.2);
    transition: 0.3s;

    &:before {
      content: "";
      position: absolute;
      width: calc(100% + 4px);
      height: 40px;
      border-radius: var(--round-mid);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border: 2px solid var(--bg);
      box-shadow: -2px -2px 6px #ffffff, 3px 3px 6px rgba(174, 174, 192, 0.4);
      transition: 0.3s;
    }
  }
}
//--- Standart button

// BUTTON--CREATE
.button--new {
  display: flex;
  position: relative;
  z-index: 9;
  flex-shrink: 0;

  &[href] {
    border: 2px solid var(--bg);
  }

  @media (max-width: 767px) {
    position: relative;
    bottom: unset;
    right: unset;
  }

  align-items: flex-end;
  justify-content: center;
  border-radius: var(--round-mid);
  color: var(--secondary);
  font-size: 32px;
  width: 36px;
  height: 36px;
  padding: 5px 10px;
  line-height: 24px;
  cursor: pointer;
  box-sizing: border-box;
  background-blend-mode: soft-light, normal;
  border: 2px solid var(--bg);
  transition: 0.3s;
  background: var(--bg);
  margin-right: 0;
  margin-left: 5px;
  box-shadow: -2px -2px 6px #ffffff, 3px 3px 6px rgba(174, 174, 192, 0.4);

  &:hover {
    border: 2px solid var(--bg);
    background-color: var(--bg);
    box-shadow: -1px -1px 3px #ffffff, 1px 1px 3px rgb(174 174 192 / 40%);
    transition: 0.3s;
  }

  &:focus,
  &:active {
    border: 2px solid var(--bg);
    background-color: var(--bg);
    box-shadow: inset -1px -1px 1px rgba(255, 255, 255, 0.7),
      inset 1.5px 1.5px 1px rgba(174, 174, 192, 0.2);
    transition: 0.3s;

    &:before {
      content: "";
      position: absolute;
      width: 36px;
      height: 36px;
      border-radius: var(--round-mid);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border: 2px solid var(--bg);
      box-shadow: -2px -2px 6px #ffffff, 3px 3px 6px rgba(174, 174, 192, 0.4);
      transition: 0.3s;
    }
  }
}
.button--flow {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  bottom: 17px;
  right: 17px;

  &.add-scroll {
    right: 25px;
  }

  width: 40px;
  height: 40px;
  border-radius: calc(var(--round-mid) - 4px);
  background-color: var(--secondary);
  color: #fff;
  cursor: pointer;
  z-index: 9;
  box-shadow: -2px -2px 6px #ffffff, 3px 3px 6px rgba(174, 174, 192, 0.4);
  transition: 0.25s;

  @media (max-width: 768px) {
    right: 18px;
  }

  @media (max-width: 767px) {
    display: none;
  }

  &:hover {
    transition: 0.25s;
    background-color: #fff;
    color: var(--secondary);
  }
}
//--- BUTTON--CREATE

.admin__wrapper {
  display: flex;
  background-color: var(--bg);
  /* padding: 10px; */
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.form-wrapper {
  border-radius: var(--round-mid);
  padding: 20px;
  overflow-y: auto;
  background: var(--secondary);
  width: 500px;
  margin: auto;
  margin-top: 0;
}

// Editor
.ckeditor__wrapper {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  min-height: 250px;
  &.full {
    flex: 1;
  }
  .ck-editor {
    display: flex;
    flex-direction: column;
    height: 100% !important;
    .ck-editor__main {
      flex: 1;
      margin-top: 10px;
    }
  }
}
.ck-content {
  height: 100%;
  border: 1px red solid;
  background: transparent !important;
  border: none !important;
  box-shadow: inset -2px -2px 4px #fafbff, inset 2px 2px 4px 0px #a6abbd;
  border-radius: var(--round-mid) !important;
}
.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
  box-shadow: inset -3px -3px 4px #fafbff, inset 3px 3px 4px 0px #a6abbd !important;
}
.ck-toolbar {
  background: transparent !important;
  border: none !important;
  padding: 0 !important;
  > .ck-toolbar__items > :not(.ck-toolbar__line-break) {
    margin-right: 10px !important;
  }
}
.ck-file-dialog-button {
  display: none !important;
}
//--- Editor

// Checkbox
input.checkbox {
  appearance: none;
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  margin: 0;
  margin-left: 10px;
  box-shadow: -2px -2px 4px #fafbff, 2px 2px 4px 0px #a6abbdab,
    inset -2px -2px 4px #fafbff00, inset 2px 2px 4px 0px #a6abbd00;
  position: relative;
  /* transition: 1.5s; */
  &:checked {
    box-shadow: inset -2px -2px 4px #fafbff, inset 2px 2px 4px 0px #a6abbd,
      -2px -2px 4px #fafbff00, 2px 2px 4px 0px #a6abbd00;
    transform: scale(1.2);
    transition: 1s;
    &:before {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
      transition: 0.5s;
    }
  }
  &:before {
    content: "";
    appearance: none;
    position: absolute;
    width: 50%;
    height: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0);
    border-radius: var(--round-high);
    background-color: var(--dark-color);
    transition: 0.5s;
    opacity: 0;
  }
}
//--- Checkbox

// Datepicker в формі
.datepicker-target {
  margin-bottom: 12px !important;
  div,
  label,
  input {
    width: 100%;
  }
  label {
    margin-bottom: 5px;
  }
  .dp__input {
    color: var(--dark-color);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0 10px;
    font-size: 15px;
    line-height: 38px;
    text-align: center;
    border-radius: var(--round-mid);
    margin-bottom: 0;
    background: linear-gradient(
        317.7deg,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(255, 255, 255, 0.4) 105.18%
      ),
      var(--bg);
    background-blend-mode: soft-light, normal;
    border: 1px solid rgba(255, 255, 255, 0.4);
    box-shadow: inset -2px -2px 4px #fafbff, inset 2px 2px 4px 0px #a6abbd;
    &::placeholder {
      color: var(--dark-color) !important;
    }
  }
}
//--- Datepicker в формі

// ANIMATIONS
.alert-enter-active,
.alert-leave-active {
  transition: all 0.4s ease;
}

.alert-enter-from,
.alert-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.value-move,
.value-enter-active,
.value-leave-active {
  transition: all 0.4s ease;
}

.value-enter-from,
.value-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.value-leave-active {
  position: absolute;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@keyframes opacity {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes translate {
  from {
    transform: translateX(-20px);
  }

  to {
    transform: translateX(15px);
  }
}

@keyframes text-translate {
  from {
    text-align: left;
  }

  to {
    text-align: right;
  }
}

@-webkit-keyframes background {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@-moz-keyframes background {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes background {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

//--- ANIMATIONS
</style>
